<template>
  <div>
    <InfoDetails />

  </div>
</template>

<script>

import InfoDetails from '../components/InfoDetails.vue'




export default {
  components: {
    InfoDetails,
  },

}


</script>
